import React, {useState, useEffect} from "react"

import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const Authorization = () => {
    return(
        <div className="authorization">
            <h2>Autorización para solicitar Reportes de Crédito</h2>  
            {/* <p>Por este conducto autorizo expresamente a Consorcio AAA Elite S de RL de CV (en adelante Mi Mejor Hipoteca) para que, por medio de sus funcionarios facultados, lleve acabo investigaciones sobre mi comportamiento crediticio en las sociedades de información crediticia que estime conveniente.</p>  
            <p>Así mismo, declaro que conozca la naturaleza y alcance de la información que se solicitara, del uso que se hará de tal información y que podrán realizar consultas perdiódicas de mi historial crediticio, consiento que esta autorización tenga vigencia de 3 años contados a partir de hoy, y en su caso mientras mantengamos relación jurídica.</p>   */}

            <p>Autorizo expresamente a Consorcio AAA Elite S de RL de CV(en adelante Mi Mejor Hipoteca), para que lleve a cabo investigaciones sobre mi comportamiento Crediticio en las sociedades de Información Crediticia (SIC) que estime conveniente. Conozco la naturaleza y alcance de la información que se solicitará, del uso que se le dará y que se podrán realizar consultas periódicas de mi historial crediticio.</p>
            <p>Consiento que esta autorización tenga una vigencia de 3 años contados a partir de hoy, y en su caso mientras mantengamos relación jurídica.</p>
            <p>Acepto que este documento quede bajo propiedad de "Mi Mejor Hipoteca" para efectos de control y cumplimiento del artículo 28 de la LRSIC.</p>
        </div>
    )
}

const Step10 = (props) => {
    const {handleData, data} = props;
    const [autorization, setAutorization] = useState(data?.autorizacion);
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // console.log('status', autorization)
        if(autorization) {
            handleData(autorization, 'autorizacion')
        }
    }, [autorization])

    const handleConfirm = (e) => {
        if(!disabled){
            let value = e.target.value;
            let code = data?.verificado?.code;

            if(value.length >= 4) {
                value = Number(value)
                if(value === code) {
                    setAutorization(true)
                    setError(null)
                    setDisabled(true)
                } else {
                    setError("El codigo es incorrecto.")
                }
            }  else {
                setError("Ingresa tu codigo.")
            }
        }
    }

    const handleDisabled = (e) => {
        let value = e.target.checked;
        setDisabled(!value)
    }

    return (
        <>
            {/* <h2>Autorización para consultar historial crediticio</h2> */}
            <Authorization />
            <FormGroup>
                <FormControlLabel 
                    control={
                        <Switch 
                            onChange={handleDisabled} 
                            defaultChecked={autorization}
                            disabled={autorization || false}
                        />
                    } 
                    // control={<Checkbox onClick={handleDisabled} />} 
                    // label="Autorizo a Consorcio AAA Elite S de RL de CV(Mi Mejor Hipoteca) a consultar mi historial crediticio de cualquier SIC que estime conveniente." 
                    label="Autorizo"
                />
            </FormGroup>
            
            <h2>Ingresa nuevamente el código</h2>

            <TextField
                id="code" 
                label="Código" 
                type="number"
                variant="filled" 
                fullWidth
                defaultValue={autorization ? data?.verificado?.code : ''}
                onChange={handleConfirm}
                disabled={disabled}
                error={error !== null}
                helperText={error}
            /> 

        </>
    )
}

export { Authorization }

export default Step10
