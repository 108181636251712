import React, {useState, useEffect} from "react"

import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import RfcFacil from 'rfc-facil';

const Step6 = (props) => {
    const {handleData, data, model} = props;
    const [user, setUser] = useState(data?.usuario || null);
    const [rfc, setRfc] = useState(user?.RFC || null);
    const [error, setError] = useState(null);

    useEffect( () => {
        if(rfc || user?.RFC) {
            // console.log("update", user)
            handleData(user, "usuario")
            model.setPersona(user)
        }

        if(rfc === null, user?.apellidoPaterno && user?.fechaNacimiento) {
            console.log('Generar rfc')
            defaultRfc()
        }
    }, [user])

    useEffect( () => {
        // console.log('antes de renderear')
        if(rfc) {
            // console.log("Update rfc", rfc)
            setUser({...user, RFC: rfc})
        }

    }, [rfc])

    const handleRfc = (e) => {
        let rfc = e?.target?.value?.toUpperCase();
        if(rfc.length === 13) {
            setRfc(rfc)
            setError(null)
        } else {
            setError("Ingresa tu RFC")
        }
    }

    const defaultRfc = () => {

        let generateRfc = RfcFacil.forNaturalPerson({
            name: `${user?.primerNombre} ${user?.segundoNombre}`,
            firstLastName: user?.apellidoPaterno,
            secondLastName: user?.apellidoMaterno,
            day: user?.fechaNacimiento?.dia,
            month: user?.fechaNacimiento?.mes,
            year: user?.fechaNacimiento?.año
         });

        // console.log('rfc', generateRfc)

        setRfc(generateRfc)
    }

    return (
        <>
            <h2>¿Cuál es tu RFC?</h2>  
            {/* <p>El valor del inmueble nos ayuda a estimar el máximo de crédito que podrías conseguir.</p> */}
            {
                rfc == null
                ?
                    user?.apellidoPaterno && user?.fechaNacimiento
                    ? <Alert severity='info'>Generando tu RFC.</Alert>
                    : <Alert severity='info'>Completa tus datos para generar tu RFC.</Alert>
                :
                <>
                    <TextField
                        id="rfc" 
                        className="rfc-input"
                        label="RFC" 
                        variant="filled" 
                        defaultValue={rfc}
                        onChange={handleRfc}
                        error={error != null}
                        autoComplete="off"
                        helperText={error}
                        fullWidth
                    /> 
                    <Alert severity='info'>Verifica que tu RFC sea correcto.</Alert>
                </>
            }

        </>
    )
}

export default Step6
