import React, {useEffect, useState} from "react"

import Button from '@mui/material/Button';

const StepInit = (props) => {
    const {handleData, data, model} = props;
    const [selected, setSelected] = useState(data?.interes);

    useEffect(() => {
        if(selected) {
            handleData(selected, "interes")
            model.setServicios({proposito: selected})
        }
    }, [selected])

    const contents = [
        {
            title: "Adquirir un inmueble",
            content: "Crédito para comprar una vivienda"
        },
        {
            title: "Mejorar mi crédito actual",
            content: "Refinanciar tu crédito  hipotecario"
        },
        {
            title: "Obtener liquidez",
            content: "Utilizando tu propiedad como garantia"
        }
    ]

    const handleSetField = (e) => {
        let selected = e.target.value;
        setSelected(selected)
    }

    return (
        <>
            <h2>¿Qué estás buscando?</h2>  
            <p>Elige el tipo de crédito que deseas para ofrecerte una solución adecuada a tus necesidades</p>

            {
                contents.map((item, i) => {
                    return(
                        <Button key={i} size="large" variant={selected === item?.title ? "contained": "outlined"} color="secondary" onClick={handleSetField} value={item?.title} disableElevation fullWidth className="option-form">
                            <span>{item?.title}</span>
                            <span>{item?.content}</span>
                        </Button>
                    )
                })
            }

        </>
    )
}

export default StepInit
