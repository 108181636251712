// import moment, { locale } from 'moment';
import 'moment/locale/es'

export default class Precalificador {
    constructor() {
        // console.log('New model')
        this.referencia = "";
        this.interes = "";
        this.persona = {
            primerNombre: "",
            segundoNombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            RFC: "",
            fechaNacimiento: "",
            sexo: "",
            salario: "",
            nacionalidad: "MX"
        }
        this.domicilios = {
            calle: "",
            colonia: "",
            ciudad: "",
            CP: "",
            municipio: "",
            estado: "",
            pais: ""
        }
        this.contacto = {
            celular: "",
            correo: "",
            via: "",
            codigoPais: ""
        }
        this.servicios = {
            proposito: "",
            valor: ""
        }
        this.verificacion = {
            code: ""
        }
        this.refVerification = null
        this.refAfiliado = null
    }

    setRef(data) {
        this.interes = data?.interes;
        this.referencia = data?.referencia;
    }

    setAfiliado(data) {
        this.refAfiliado = data
    }

    setPersona(data) {
        if(data?.fechaNacimiento) {
            data = {...data, fechaNacimiento:`${data?.fechaNacimiento?.año}-${data?.fechaNacimiento?.mes}-${data?.fechaNacimiento?.dia}`}
        }
        this.persona = {...this.persona, ...data}
    }

    setContacto(data) {
        this.contacto = {...this.contacto, ...data}
    }

    setDomicilio(data) {
        this.domicilios = {...this.domicilios, ...data}
    }

    setServicios(data) {
        // console.log('Set servicios: ', data)
        this.servicios = {...this.servicios, ...data}
        // this.referencia = data?.referencia;
        // this.interes = data?.interes;
    }

    setVerificacion(data) {
        this.verificacion = {...this.verificacion, ...data}
        this.refVerification = data?.ref
    }

}