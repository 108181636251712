import React, {useEffect, useState} from "react"

import axios from 'axios';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const Step9 = (props) => {
    const {handleData, data, model} = props;
    const [verified, setVerified] = useState(data?.verificado)

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const celular = data?.contacto?.celular;

    useEffect(() => {
        // console.log('init', data?.contacto)
        // console.log('verificado', verified)
        if(verified) {
            handleData(verified , 'verificado')
            model.setVerificacion(verified)
        } else {
            handleData(null, 'verificado')
        }
        
        if(!verified || verified?.celular !== celular) {
            handleCode()
        }
    }, [verified])

    const handleCode = (event) => {
        setLoading(true)

        let query = {
            url: '/api/v1/sms',
            data: {
                celular: celular,
                via: data?.contacto?.via,
                codigoPais: data?.contacto?.codigoPais
            }
        }

        let method = event || query;

        let token = '123456';

        axios({
            method: 'post',
            url: method?.url,
            data: method?.data,
            headers: {
                "x-access-token": token
            }
        })
        .then( response => {
            let res = response?.data;
            // console.log(res)

            setVerified({...res, code: method?.data?.code , celular: celular})

            setLoading(false)
        })
        .catch( err => {
            // console.log(err)
            setLoading(false)
        })
    }

    const checkCode = (e) => {
        setLoading(true)
        let value = e.target.value;
        if(value.length >= 6) {
            value = Number(value)
            // console.log('code', value)
            setError(null)
            handleCode({
                url: '/api/v1/sms/check',
                data: {
                    celular: celular,
                    code: value
                }
            })
        } else {
            setLoading(false)
            setError("Ingresa los 6 digitos.")
        }
    }

    const resendCode = () => {
        handleCode({
            url: '/api/v1/sms/resend',
            data: {
                celular: celular,
                via: data?.contacto?.via,
                codigoPais: data?.contacto?.codigoPais
            }
        })
    }

    return (
        <>
            <h2>Escribe el código NIP que recibiste en tu celular</h2>

            <div className="wrapper-column">
                <p className="phone-number">Celular: {celular || "Ingresa tu numero de celular."}</p>

                {
                    loading
                    ? <CircularProgress color="secondary" />
                    : <Alert severity={verified?.status}>{verified?.message}</Alert>
                }

            </div>

            <TextField
                id="code" 
                label="Código" 
                variant="filled" 
                type="number"
                onChange={checkCode}
                disabled={!celular}
                defaultValue={verified?.code}
                fullWidth
                error={error != null}
                helperText={error}
            /> 
            
            <Button
                onClick={resendCode}
                disabled={!celular}
                sx={{
                    width: 'fit-content'
                }}
            >Reenviar código</Button>

        </>
    )
}

export default Step9
