import React, {useState, useEffect, useRef} from "react"

import { useQueryParam, StringParam } from "use-query-params";

// import {
//   Link
// } from 'gatsby';

import { Link } from "gatsby-theme-material-ui";

import Layout from "../layout"

import '../styles/app.scss';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Tooltip from '@mui/material/Tooltip';

//precalificador
import InitForm from "../components/form/step-init"
import Step2 from "../components/form/step-2"
import Step3 from "../components/form/step-3"
import Step4 from "../components/form/step-4"
import Step5 from "../components/form/step-5"
import Step6 from "../components/form/step-6"
import Step7 from "../components/form/step-7"
import Step8 from "../components/form/step-8"
import Step9 from "../components/form/step-9"
import Step10 from "../components/form/step-10"
import { Authorization } from "../components/form/step-10"

import {getByCode} from "../services/getAffilates"

// Model
import Model from '../utils/precalificador'
import { Typography } from "@mui/material";

function HorizontalLabelPositionBelowStepper(props) {
  const {activeStep, completed, handleStep, steps = []} = props

  return (
    <Box>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps.map((step, i) => (
          <Tooltip key={i} title={step?.title} arrow>
            <Step completed={completed[i]}>
              {
                step.disabled && !completed[i]
                ? <StepLabel className="step-disable">{step?.title}</StepLabel>
                : <StepButton onClick={handleStep(i)}>{step?.title}</StepButton>
              }
            </Step>
          </Tooltip>
        ))}
      </Stepper>
    </Box>
  );
}

const newRequest = new Model();

const App = () => {
  const [reference, setReference] = useQueryParam("ref", StringParam);
  const [option = "Crédito", setOption] = useQueryParam("option", StringParam);
  const [data, setData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [sending, setSending] = useState(false);
  const [report, setReport] = useState({status: false});
  const [affiliate, setAffiliate] = useState(null);
  const refForm = useRef(null)

  // console.log(newRequest)
  // console.log(reference)

  const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
  ]
  
  useEffect(() => {
    if(window) {
      let top = refForm?.current?.offsetTop - 90
      window.scroll({
        top: top, 
        left: 0, 
        behavior: 'smooth'
      });
    }
  }, [activeStep])
  
  useEffect(() => {
    // console.log('data render', data)
    // console.log('model: ', newRequest?.persona)
    // console.log('completed: ', data, newRequest)

    // if(data) {
    //   handleComplete()
    // }

    if (newRequest?.refAfiliado === null) {
      newRequest?.setRef({interes: option, referencia: reference})
      if(reference != null) {
        // console.log("reference", reference)
        let affiliate = getByCode(reference)
        
        affiliate.then(res => {
          // console.log('afiliado:', res)
          if(res) {
            newRequest?.setAfiliado(res?._id)
            setAffiliate(res)
          }
        })
      }
    }
  },[data])

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    if(activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    // handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleData = (input, field) => {
    setData({...data, [field]: input})

    handleComplete()
  } 

  const handleCredit = () => {
    setSending(true)
  }

  const stepsComponents = [
    {
      title: "¿Qué estás buscando?",
      component: <InitForm model={newRequest} data={data} handleData={handleData} />
    },
    {
      title: "¿Cuál es el valor aproximado del inmueble que buscas?",
      component: <Step2 model={newRequest} data={data} handleData={handleData} />
    },
    {
      title: "¿Cuál es tu ingreso mensual?",
      component: <Step3 model={newRequest} data={data} handleData={handleData} />
    },
    {
      title: "¿Cuál es tu nombre?",
      component: <Step4 model={newRequest} data={data} handleData={handleData} />
    },
    {
      title: "¿Cuál es tu fecha de nacimiento?",
      component: <Step5 months={months} model={newRequest} data={data} handleData={handleData} />
    },
    {
      title: "¿Cuál es tu RFC?",
      component: <Step6 model={newRequest} data={data} handleData={handleData} />
    },
    {
      title: "¿Cuál es tu dirección?",
      component: <Step7 model={newRequest} data={data} handleData={handleData} />
    },
    {
      title: "Datos de contacto",
      component: <Step8 model={newRequest} data={data} handleData={handleData} />
    },
    {
      title: "Escribe el código que recibiste en tu teléfono",
      component: <Step9 model={newRequest} data={data} handleData={handleData}/>,
      disabled: true
    },
    {
      title: "Autorización para consultar historial crediticio",
      component: <Step10 model={newRequest} data={data} handleData={handleData}/>,
      disabled: true
    }
  ]

  let steps = stepsComponents.map(step => {
    return {
      title: step?.title, 
      disabled: step?.disabled
    }
  })

  return (
    <Layout>

        <section className="wrapper-form">
          <h1>Precalificador</h1>
          <p>Descubre tu monto de crédito en 3 minutos</p>

          <div className="wrapper-progress-steps">
            <HorizontalLabelPositionBelowStepper 
              activeStep={activeStep} 
              completed={completed}
              handleStep={handleStep}
              steps={steps}
            />
          </div>

          <div ref={refForm} className="wrapper-steps-form">

            {
              stepsComponents[activeStep].component
            }

            <div className="wrapper-btn">

              <Button role="btn-back" variant="outlined" onClick={handleBack} disabled={activeStep === 0} disableElevation>Anterior</Button>
              {
                activeStep === 9
                ? 
                  <Button 
                    role="btn-next" 
                    variant="contained" 
                    disabled={
                        data?.autorizacion === null 
                        ? true 
                        : !data?.autorizacion 
                    } 
                    disableElevation
                    // onClick={handleCredit}
                    component={Link}
                    to="/resultado"
                    state={{userData: newRequest}}
                  >Finalizar</Button>
                : 
                  <Button 
                    role="btn-next" 
                    variant="contained" 
                    onClick={handleNext} 
                    disabled={
                      activeStep === 7 
                      ? completedSteps() < 8
                      : activeStep === 8 
                        ? !data?.verificado?.status?.code && data?.verificado?.status !== 'success'
                        : false
                    } 
                    disableElevation
                  >Siguiente</Button>
              }
            </div>

          </div>  

          {
            affiliate
            ?
              <Box
                sx={{
                  mt: 6,
                  py: 2,
                  borderTop: '1px solid #C4C4C4'
                }}
              >
                <Typography sx={{mb: 1}} component="p" variant="subtitle2">Asesor</Typography>
                <Typography variant="body2" sx={{textTransform: 'capitalize'}}>{affiliate?.name}</Typography>
                {/* <Typography variant="body2" capitalize>{affiliate?.code}</Typography> */}
              </Box>
            : null
          }


          {
            stepsComponents[activeStep].secondaryComponent !== null
            ? stepsComponents[activeStep].secondaryComponent
            : null
          }
        </section>

    </Layout>
  )
}

export default App
