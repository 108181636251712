import React, {useState, useEffect} from "react"
import axios from 'axios'

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

let handleList = []

const Step7 = (props) => {
    const {handleData, data, model} = props;
    const [address, setAddress] = useState(data?.direccion)
    
    const [error, setError] = useState(null)
    const [listColonia, setListColonia] = useState(handleList)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // console.log(address)
        if(address?.calle && address?.colonia && address?.CP) {
            handleData(address, 'direccion')
            model.setDomicilio(address)
        }
    }, [address])

    const getColonies = (data) => {
        setError(null)
        let input = data.target.value

        if(input.length === 5) {

            let endpoint = `/api/v1/cp/search?codigo_postal=${input}`

            let token = '123456';

            let handleResponse = (res) => {
                // console.log(res)
                setLoading(false)

                if(res.status === 200 && !res?.data?.error) {

                    setListColonia(res?.data?.response?.asentamiento)
                    handleList = res?.data?.response?.asentamiento

                    setAddress({
                        ...address, 
                        municipio: res?.data?.response?.municipio, 
                        ciudad: res?.data?.response?.ciudad, 
                        estado: res?.data?.response?.estado,
                        CP: res?.data?.response?.cp,
                        pais: res?.data?.response?.pais
                    })

                } else {
                    setListColonia([])
                    handleList = []
                    
                    setError({
                        message: res?.data?.error_message
                    })
                }
            }

            if(input !== address?.cp) {
                setLoading(true)
                axios({
                    method: 'get',
                    url: endpoint,
                    headers: {
                        "x-access-token": token
                    }
                })
                    .then( res => handleResponse(res))
                    .catch(err => {
                        setLoading(false)
                        setError({
                            message: "Asegurate de ingresar solo numeros."
                        })
                    })
            }
        } else {
            setError({
                message: "Debes ingresar 5 digitos."
            })
        }

    }

    const handleAddress = (e) => {
        let field = e.target.name;
        let value = e.target.value;

        if(value) {
            // console.log(value)
            setAddress({...address, [field]: value})
        }
    }

    return (
        <>
            <h2>¿Cuál es tu dirección?</h2>  
            {/* <p>El valor del inmueble nos ayuda a estimar el máximo de crédito que podrías conseguir.</p> */}

            <div className="wrapper-column">
                <TextField
                    id="cp" 
                    label="Código postal" 
                    variant="filled" 
                    type="number"
                    onChange={getColonies}
                    helperText={error?.message}
                    error={error != null}
                    defaultValue={address?.CP}
                    autoComplete="off"
                    noValidate
                /> 

                {
                    loading
                    ? <CircularProgress color="secondary" />
                    : null
                }

                {
                    address != null
                    ? <p className="address">Tu ubicacion es: {address?.municipio}, {address?.ciudad}, {address?.estado}, {address?.pais}</p>
                    : null
                }

            </div>
            
            {/* <Link target="_blank" href="https://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/Descarga.aspx">¿No conoces tu código postal?</Link> */}
            {
                
                address == null
                ? 
                    <Alert 
                        severity='info'
                    >Ingresa tu codigo postal</Alert>
                :
                <>
                    <TextField
                        id="colonia"
                        name="colonia"
                        label="Colonia"
                        select
                        value={address?.colonia}
                        defaultValue={address?.colonia}
                        fullWidth
                        autoComplete="off"
                        disabled={!listColonia?.length}
                        onChange={handleAddress}
                        variant="filled"
                    >
                        {
                            listColonia.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))
                        }
                    </TextField>

                    <TextField
                        name="calle"
                        id="street" 
                        label="Calle y número" 
                        variant="filled" 
                        fullWidth
                        autoComplete="off"
                        defaultValue={address?.calle}
                        onChange={handleAddress}
                    /> 
                </>
            }
        </>
    )
}

export default Step7
