import React, {useState, useEffect} from "react"
import isEmail from "validator/lib/isEmail"
import isMobilePhone from "validator/lib/isMobilePhone"

import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {
    Link
} from 'gatsby';

const Step8 = (props) => {
    const {handleData, data, model} = props;
    const [contact, setContact] = useState(data?.contacto);
    const [via, setVia] = useState(contact?.via ? contact?.via : 'sms');
    const [country, setCountry] = useState( contact?.codigoPais === '+1' || contact?.codigoPais === '+11' ? 1 : 0);
    const [error, setError] = useState(null);
    const countryCodes = [
        {
            sms: '+52',
            label: 'México',
            whatsapp: '+521'
        },
        {
            sms: '+1',
            label: 'Estados Unidos',
            whatsapp: '+11'
        }
    ]

    useEffect(() => {
        if(contact?.correo && contact?.celular) {
            // console.log('contacto ', contact)
            // console.log('country ', country)
            model.setContacto({...contact, via: via, codigoPais: countryCodes[country][via]})
            handleData({...contact, via: via, codigoPais: countryCodes[country][via]}, 'contacto')
        }
    }, [contact, via, country])

    const handleCountryCode = (e) => {
        let value = e?.target?.value;
        // console.log(value)
        setCountry(value)
    }

    const handleContact = (e) => {
        let value = e.target.value.trim();
        let name = e.target.name;
        let isValid = name === 'celular' ? isMobilePhone(value, ['es-MX', 'en-US']) : isEmail(value)
        
        if(value) {
            if(isValid)  {
                setContact({...contact, [name]: value})
                setError(null)
            } else {
                setError({
                    field: name,
                    message: `Ingresa un ${name} válido.`
                })
            }
        } else {
            setError({
                field: name,
                message: `Ingresa tu ${name}.`
            })
        }
    }

    return (
        <>
            <h2>Datos de contacto</h2>  

            <TextField
                id="email" 
                label="Correo electrónico" 
                name="correo"
                defaultValue={contact?.correo}
                variant="filled" 
                fullWidth
                onChange={handleContact}
                autoComplete="off"
                helperText={error?.field === 'correo' ? error?.message : null}
                error={error != null && error?.field === 'correo'}
            /> 

            <p>Enviaremos un código de verificación a tu celular.</p>

            <FormControl variant="filled" fullWidth>
                <InputLabel>Pais</InputLabel>
                <Select
                    value={country}
                    label="Pais"
                    onChange={handleCountryCode}
                >
                    {
                        countryCodes?.map( (cc, i) => {
                            return(
                                <MenuItem key={i} value={i}>{cc?.label}</MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>

            <TextField
                id="phone" 
                label="Celular" 
                name="celular"
                type="tel"
                variant="filled" 
                defaultValue={contact?.celular}
                fullWidth
                onChange={handleContact}
                autoComplete="off"
                helperText={error?.field === 'celular' ? error?.message : null}
                error={error != null && error?.field === 'celular'}
            /> 

            <p>¿Cuál medio de contacto prefieres?</p>

            <FormGroup 
                onChange={(e) => setVia(e?.target?.value)}
                sx={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <FormControlLabel 
                    disabled
                    control={
                        <Checkbox 
                            value="sms" 
                            name="phone" 
                            // defaultChecked={contact?.via === 'sms'} 
                            checked={via === 'sms'} 
                        />
                    }
                    label="Mensaje de texto" />
                {/* <FormControlLabel 
                    disabled
                    control={
                        <Checkbox 
                            value="whatsapp" 
                            name="phone" 
                            // defaultChecked={contact?.via === 'whatsapp'} 
                            checked={via === 'whatsapp'} 
                        />
                    } 
                    label="Whatsapp"
                    /> */}
            </FormGroup>
            
            <p>Al dar clic en 'siguiente': acepto recibir notificaciones en mi celular y correo eléctronico y que mis datos peronales sean tratados para la finalidades descritas en el <Link href="/aviso-de-privacidad" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</Link>, y acepto los <Link href="/terminos-y-condiciones" rel="noopener noreferrer" target="_blank">Términos y condiciones de Uso</Link> y la cláusula de medios electrónicos.</p>

        </>
    )
}

export default Step8
