import React, {useState, useEffect} from "react"
import isCurrency from "validator/lib/isCurrency"

import TextField from '@mui/material/TextField';

const StepInit = (props) => {
    const {handleData, data, model} = props;
    const [error, setError] = useState(null);
    const [salary, setSalary] = useState(data?.salario || null);

    useEffect(() => {
        if(salary) {
            // console.log(salary)
            handleData(salary, "salario")
            model.setPersona({salario: salary})
        }
    }, [salary])

    const handleSetField = (e) => {
        setError(null)
        let value = e.target.value;

        // let formatter = new Intl.NumberFormat('es-MX', {
        //     style: 'currency',
        //     currency: 'MXN',
        // });
        
        // value = formatter.format(value);

        if(value) {
            
            if(isCurrency(value)) {
                setSalary(value)
                setError(null)
            } else {
                setError({
                    message: "Ingresa solamente numeros."
                })
            }
        } else {
            setError({
                message: "Ingresa tu salario actual."
            })
        }
    }

    return (
        <>
            <h2>¿Cuál es tu ingreso mensual?</h2>

            <TextField
                id="salary" 
                label="Monto (MXN)" 
                type="number"
                variant="filled" 
                defaultValue={salary}
                fullWidth
                autoComplete="off"
                helperText={error?.message}
                error={error != null}
                onChange={handleSetField}
                // onBlur={handle}
            /> 

            {/* <FormControl fullWidth variant="filled">
                <InputLabel htmlFor="filled-adornment-amount">Amount</InputLabel>
                <FilledInput
                    id="filled-adornment-amount"
                    defaultValue={data?.salario}
                    onBlur={handleSetField}
                    helperText={error?.message}
                    error={error != null}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
            </FormControl> */}

        </>
    )
}

export default StepInit
