import React, {useState, useEffect} from "react";

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const Step5 = (props) => {
    const {handleData, data, months, model} = props;
    const [user, setUser] = useState(data?.usuario || null);
    const [birthday, setBirthday] = React.useState(user?.fechaNacimiento);
    const [error, setError] = useState(null);

    useEffect(() => {
        // console.log(user)
        if(user?.sexo && user?.fechaNacimiento) {
            // console.log('new user', user)
            handleData(user, "usuario")
            model.setPersona(user)
        }
    }, [user])

    useEffect(() => {
        if(birthday?.dia && birthday?.mes && birthday?.año) {
            setUser({...user, RFC: "", fechaNacimiento: birthday})
        }
    }, [birthday])

    const handleBinary = (e) => {
        setUser({...user, RFC: "", sexo: e.target.value})
    }

    const handleChange = (e) => {
        let field = e.target.name;
        let value = e.target.value;

        if(field === 'dia') {
            if(value.length == 2) {
                setBirthday({...birthday, [field]: value})
                setError()
            } else {
                setError({field: field, message: 'Ingresa 2 numeros.'})
            }

        } else if(field === 'mes') {
            setBirthday({...birthday, [field]: months?.indexOf(value) + 1})
        } else if(field === 'año') {
            if(value.length === 4) {
                setBirthday({...birthday, [field]: value})
                setError()
            } else {
                setError({field: field, message: 'Ingresa 4 numeros.'})
            }
        } else {
            setBirthday({...birthday, [field]: value})
            setError()
        }
    };

    return (
        <>
            <h2>¿Cuál es tu género?</h2> 
            {/* <p>Tus datos se protegerán bajo estandares internacionales en manejo de datos y encriptación para resguardo.</p> */}

            <div className="wrapper-column">
                <Button size="large" variant={user?.sexo === "Hombre" ? "contained": "outlined"} color="secondary" value="Hombre" onClick={handleBinary} disableElevation fullWidth className="option-form">
                    <span><PersonIcon /></span>
                    <span>Hombre</span>
                </Button>

                <Button size="large" variant={user?.sexo === "Mujer" ? "contained": "outlined"} color="secondary" value="Mujer" onClick={handleBinary} disableElevation fullWidth className="option-form">
                    <span><PersonOutlineIcon /></span>
                    <span>Mujer</span>
                </Button>
            </div>

            <h2>¿Cuál es tu fecha de nacimiento?</h2>  

            <div className="wrapper-column">

                <TextField
                    name="dia" 
                    label="Dia" 
                    variant="filled" 
                    type="number"
                    helperText={error?.field === 'dia' ? error?.message : null}
                    error={error != null && error?.field === 'dia'}
                    defaultValue={user?.fechaNacimiento?.dia}
                    // value={birthday?.dia}
                    autoComplete="off"
                    onChange={handleChange}
                /> 

                <TextField
                    name="mes"
                    role="mes"
                    select
                    label="Mes"
                    variant="filled"
                    autoComplete="off"
                    // defaultValue={user?.fechaNacimiento?.mes}
                    value={months[birthday?.mes - 1] || ""}
                    onChange={handleChange}
                    fullWidth
                >
                    {
                        months.map((option, i) => (
                            <MenuItem key={i} value={option}>
                                {option}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <TextField
                    name="año" 
                    label="Año" 
                    variant="filled" 
                    type="number"
                    autoComplete="off"
                    defaultValue={user?.fechaNacimiento?.año}
                    // value={birthday?.año}
                    helperText={error?.field === 'año' ? error?.message : null}
                    error={error != null && error?.field === 'año'}
                    onChange={handleChange}
                /> 
            </div>

        </>
    )
}

export default Step5
