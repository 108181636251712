import React, {useState, useEffect} from "react"

import Button from '@mui/material/Button';

const StepInit = (props) => {
    const {handleData, data, model} = props;
    const [selected, setSelected] = useState(data?.valor);

    useEffect(() => {
        // console.log('test data', data)
        if(selected) {
            handleData(selected, "valor")
            model.setServicios({valor: selected})
        }
    }, [selected])

    const contents = [
        {
            title: "Menos de 1 millon."
        },
        {
            title: "Entre 1 millon y 2 millones."
        },
        {
            title: "Entre 2 y 3 millones."
        },
        {
            title: "Entre 3 y 4 millones."
        },
        {
            title: "Más de 4 millones."
        }
    ]

    const handleSetField = (e) => {
        let selected = e.target.value;
        setSelected(selected)
    }

    return (
        <>
            {
                data?.interes == "Adquirir un inmueble"
                ? <h2>¿Cuál es el valor aproximado del inmueble que buscas?</h2> 
                : <h2>¿Cuál es el valor aproximado de tu inmueble?</h2>  
            }
            <p>El valor del inmueble nos ayuda a estimar el máximo de crédito que podrías conseguir.</p>

            {
                contents.map((item, i) => {
                    return(
                        <Button key={i} size="large" variant={selected === item.title ? "contained": "outlined"} color="secondary" onClick={handleSetField} value={item.title} disableElevation fullWidth className="option-form">
                            <span>{item.title}</span>
                        </Button>
                    )
                })
            }

        </>
    )
}

export default StepInit
