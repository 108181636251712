import React, {useState, useEffect} from "react"

import TextField from '@mui/material/TextField';

const FieldComponent = (props) => {
    const {data = "", fieldName = "", handleDataField} = props;
    const [error, setError] = useState(null);

    const handleField = (e) => {
        let value = e.target.value.trim();

        if(!value) {
            setError(`Ingresa tu ${fieldName}.`)
        } else {
            setError(null)
        }
    }

    const handle = (e) => {
        let value = e.target.value;
        if(!error) {
            setError(null)
            handleDataField(value, fieldName?.replace(' ', ''))
        }
    }

    return(
        <TextField
            id={fieldName} 
            label={ fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} 
            variant="filled"
            defaultValue={data}
            onChange={handleField}
            autoComplete="off"
            onBlur={handle}
            helperText={error}
            error={error != null}
            fullWidth 
        /> 
    )
}

const Step4 = (props) => {
    const {handleData, data, model} = props;
    const [user, setUser] = useState(data?.usuario || null);

    const fields = [
        "primer Nombre",
        "segundo Nombre",
        "apellido Paterno",
        "apellido Materno"
    ]

    useEffect(() => {
        // console.log('test', user)
        if(user?.primerNombre && user?.apellidoPaterno) {
            handleData(user, "usuario")
            model.setPersona(user)
        }
    }, [user]);

    const handleDataUser = (value, field) => {
        // setError(null)
        if(value) {
            setUser({...user, RFC: "", [field]: value.trim()})
        }
    }

    return (
        <>
            <h2>¿Cuál es tu nombre?</h2>  
            {/* <p>Tus datos se protegerán bajo estandares internacionales en manejo de datos y encriptación para resguardo.</p> */}

            {
                fields.map( input => {
                    return(
                        <FieldComponent key={input} handleDataField={handleDataUser} data={user ? user[input?.replace(' ', '')] : null} fieldName={input} />
                    )
                })
            }
            {/* <TextField
                id="salary" 
                label="Nombre" 
                variant="filled"
                defaultValue={user?.nombre}
                onChange={(e) => handleDataField(e.target.value, "nombre")}
                
                helperText={returnMessage("nombre")}
                error={returnIsError("nombre")}
                fullWidth 
            /> 

            <TextField
                id="salary" 
                label="Appellido Paterno" 
                variant="filled"
                defaultValue={user?.apellidoPaterno}
                onChange={(e) => handleDataField(e.target.value, "apellidoPaterno")}
                
                helperText={returnMessage("apellidoPaterno")}
                error={returnIsError("apellidoPaterno")}
                fullWidth 
            /> 

            <TextField
                id="salary" 
                label="Appellido Materno" 
                variant="filled"
                defaultValue={user?.apellidoMaterno}
                onChange={(e) => handleDataField(e.target.value, "apellidoMaterno")}
                
                helperText={returnMessage("apellidoMaterno")}
                error={returnIsError("apellidoMaterno")}
                fullWidth 
            />  */}

        </>
    )
}

export default Step4
